.btn {
  font-weight: 600;
}

.card {
  &.card--semitransparent {
    @media (min-width: 992px) {
      background-color: rgba(#fff, .6);
      backdrop-filter: blur(8px);
      box-shadow: 0 2px 5px rgba(#000, .03);
    }
  }
}
