.cookies-table {
  margin: 2rem 0;
  th,
  td {
    border: 1px solid rgba(#000, .12);
    padding: .5rem .75rem;
    line-height: 1.2;
    font-size: .875rem;
  }
}
