.home {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.home-car {
  width: 400px;
  max-width: 100%;
  height: auto;
}
