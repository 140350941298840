html, body {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

#root {
  height: 100%;
  background-color: #fff;
  background-image: url('images/coche-bg.jpg');
  background-repeat: no-repeat;
  @media (min-width: 992px) {
    background-position: center center;
    background-attachment: fixed;
    overflow: visible;
  }
}

a {
  text-decoration: none;
  font-weight: 700;
}

.App {
  padding-bottom: 4rem;
}

.cookies_modal-backdrop {
  position: fixed;
  inset: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background-color: rgba(#000, .12);
  &.closed {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
}
.cookies_modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 700px;
  max-width: 95%;
  height: 100%;
  max-height: 500px;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 3px 5px rgba(#000, .06), 0 5px 8px rgba(#000, .12);
}
.cookies_title {
  width: 100%;
  border-bottom: 1px solid rgba(#000, .12);
  text-align: center;
  padding: .25rem .5rem;
  font-size: 1.25rem;
  font-weight: 700;
}
.cookies_content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.5rem .5rem;
  @media (min-width: 992px) {
    padding: 1rem 1.5rem 1.5rem;
  }
}
.cookies_actions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid rgba(#000, .12);
  .btn {
    flex-grow: 1;
    border-radius: 0;
  }
}
