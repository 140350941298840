.header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: .5rem auto;
  @media (min-width: 992px) {
    flex-flow: row nowrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 1.5rem auto;
  }
}

#menu-toggler {
  position: fixed;
  top: .25rem;
  right: .25rem;
  z-index: 51;
  @media (min-width: 992px) {
    display: none !important;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}

.menu {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  .btn {
    margin-bottom: .5rem;
  }
  @media (max-width: 992px) {
    //display: none;
    position: fixed;
    inset: 0;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: -5;
    overflow-y: auto;
    pointer-events: none;
    transition: opacity .25s .1s ease;
    &.show {
      display: flex;
      opacity: 1;
      background-color: rgba(#fff, .9);
      z-index: 50;
      pointer-events: auto;
      .btn {
        font-size: 1.5rem;
      }
    }
  }
  @media (min-width: 992px) {
    flex-flow: row wrap;
    justify-content: flex-end;
    max-width: 1200px;
    margin-top: 0;
    .btn {
      margin-right: .5rem;
      margin-bottom: 0;
    }
  }
}

.logos {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 .5rem;
  @media (min-width: 500px) {
    width: auto;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}

.logo {
  margin: .5rem 0;
  max-width: 100%;
  height: 60px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (min-width: 500px) {
    width: auto;
    height: 60px;
    margin: 0 3rem 0 0;
    &:last-child {
      margin-right: 0;
    }
    &.logo--ayto {
      height: 80px;
    }
  }
}

.nav-btn {
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
}
