$white: #fff;
$gray-50: #FAFAFA;
$gray-100: #F5F5F5;
$gray-200: #EEEEEE;
$gray-300: #E0E0E0;
$gray-400: #BDBDBD;
$gray-500: #9E9E9E;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$black: #000;

// scss-docs-start color-variables
$blue:    #2196F3;
$indigo:  #3F51B5;
$purple:  #9C27B0;
$pink:    #E91E63;
$red:     #f44336;
$orange:  #FF9800;
$yellow:  #FFEB3B;
$green:   #4CAF50;
$teal:    #009688;
$cyan:    #00BCD4;
// scss-docs-end color-variables
$dark-red: #B71C1C;

$colors: (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800,
    "dark-red":   $dark-red,
);

$primary: $dark-red;
$secondary: $gray-200;
